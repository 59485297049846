import type { RouteLocationNormalized } from 'vue-router'
import { defineNuxtRouteMiddleware, useRouter, useState } from '#app'

type RouteData = {
  path: string
  query: Record<string, any>
}

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
  const lastRoute = useState<RouteData | null>('lastRoute', () => null)
  if (lastRoute.value && lastRoute.value.path === to.path) {
    if (JSON.stringify(lastRoute.value.query) !== JSON.stringify(to.query)) {
      useRouter()
        .push({
          path: to.path,
          query: to.query,
        })
        .catch((err) => {
          console.warn('Navigation error:', err)
        })
    }
  }

  lastRoute.value = { path: to.path, query: to.query }
})
