import revive_payload_client_ScwjCZO2TgdBwbUB_ig5RPWuKKH7ltsM_zzPff_NzGA from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1cacIJ3ZQpFkkNAtQoEms5xAP5rTPQN2GY2GAk6Z3co from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7BUXXADtaq6W1GWRJ4FzZWoGRhKwjUrWZIJgrJYm0Lg from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yLK63kcMFWJzd5rls7PJyEyU__em8OtokbOBMMcvSUw from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1_YkdF2lv5NQsdgwUp8gimyolO_KXPk_8rnSn0vWW9s from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Y81SStb_k_d6F1iCGKtCDdAzO7b1Z4HuOplVaVe_E_g from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1hHdYEsmOvX_B3WHgUgJDJevFSjWvxppk0W3TH_6ST4 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_80fqPhIFs6ogruct7wY_W1M4RZavhZ31xcKGr7oLgmU from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.36.0_typescript@5.4.5_vue@3.5.13_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/components.plugin.mjs";
import prefetch_client_DD0pc0uApYwY9U3mP0hWJtxwCZPHx5CATkFrTkclCI0 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/pwa-icons-plugin.ts";
import pwa_client_rhlc47RRA8CWUkRXgWpHJ4iHsuuZJK_e2cbUADlgp3M from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.0.11_magicast@0.3.5_vite@6.2.2_@type_ad0cc2b7d92023f4be01596ce1d1c35d/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/unocss.mjs";
import plugin_DlpPMKJZLf_fIIteiqbY3yIVEupFWJuFIHByzemI2HU from "/builds/rialtic/workflows/console-ui/packages/protected/src/runtime/plugin.ts";
import formkitPlugin_Kw9Zxpc32ie3g9uaRfiavVL004WiVJNxPefwwMCJMnU from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/formkitPlugin.mjs";
import pendo_tkGmWQoRDlhHENDAVi2Hn0Sq2CNzvYFYcsyj5v2SShU from "/builds/rialtic/workflows/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_SGheBrR9i4RmrpgBtOaw6N_f0GJBpOVIEbnJz18fybE from "/builds/rialtic/workflows/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_iV7PrbC1htQjhkNgsmxIwZrbi7G_UJeds9xfnbgHL3Q from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import plugin_XOzb4bmh6diI_DbsPRgKnWH0vog2CqSI4vRxE4zUQhE from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth/src/runtime/plugin.ts";
import plugin_qW_PdTb9mGBBa_Iir6NehiB2Z_jp0MgFoPvI2JUTMZY from "/builds/rialtic/workflows/console-ui/packages/ui/src/runtime/plugin.ts";
export default [
  revive_payload_client_ScwjCZO2TgdBwbUB_ig5RPWuKKH7ltsM_zzPff_NzGA,
  unhead_1cacIJ3ZQpFkkNAtQoEms5xAP5rTPQN2GY2GAk6Z3co,
  router_7BUXXADtaq6W1GWRJ4FzZWoGRhKwjUrWZIJgrJYm0Lg,
  payload_client_yLK63kcMFWJzd5rls7PJyEyU__em8OtokbOBMMcvSUw,
  navigation_repaint_client_1_YkdF2lv5NQsdgwUp8gimyolO_KXPk_8rnSn0vWW9s,
  check_outdated_build_client_Y81SStb_k_d6F1iCGKtCDdAzO7b1Z4HuOplVaVe_E_g,
  chunk_reload_client_1hHdYEsmOvX_B3WHgUgJDJevFSjWvxppk0W3TH_6ST4,
  plugin_vue3_80fqPhIFs6ogruct7wY_W1M4RZavhZ31xcKGr7oLgmU,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_DD0pc0uApYwY9U3mP0hWJtxwCZPHx5CATkFrTkclCI0,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_rhlc47RRA8CWUkRXgWpHJ4iHsuuZJK_e2cbUADlgp3M,
  unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII,
  plugin_DlpPMKJZLf_fIIteiqbY3yIVEupFWJuFIHByzemI2HU,
  formkitPlugin_Kw9Zxpc32ie3g9uaRfiavVL004WiVJNxPefwwMCJMnU,
  pendo_tkGmWQoRDlhHENDAVi2Hn0Sq2CNzvYFYcsyj5v2SShU,
  datadog_SGheBrR9i4RmrpgBtOaw6N_f0GJBpOVIEbnJz18fybE,
  plugin_iV7PrbC1htQjhkNgsmxIwZrbi7G_UJeds9xfnbgHL3Q,
  plugin_XOzb4bmh6diI_DbsPRgKnWH0vog2CqSI4vRxE4zUQhE,
  plugin_qW_PdTb9mGBBa_Iir6NehiB2Z_jp0MgFoPvI2JUTMZY
]