import routerOptions0 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/rialtic/workflows/console-ui/apps/console/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}