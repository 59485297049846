
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91rid_93Ic1_45L3pj0PyUnmaFrLfQ2asxT_45aWTSHpOMyVHFDv2J8Meta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue?macro=true";
import { default as indexua_iuzXzG5IBazDO5ZWOAjR5ugPNUPcqYVl8ypf4DfoMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue?macro=true";
import { default as _91connectorId_93pBb8NfODzgcz2VGlxGi0DRKkKmFu8lW9LrXy88fSbnYMeta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as api_45clientsWv8kAIq4bQChxIQ_VprJkAb66fqGxLM9oh3nYO7IGT8Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/account/api-clients.vue?macro=true";
import { default as indexizq3_456UQYZFbtZbEy8LRxgQvoFts_wJe23uj5AAp1_gMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/account/index.vue?macro=true";
import { default as usersVq7i_SzZTTNdzAlN_Zu3iuakozZhipWDZS6r7Nn_jNIMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/account/users.vue?macro=true";
import { default as api_45adminnUrVtPvXDnFVKMt2rAqPOHosqqxxYXdByywxJqaSoJwMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/api-admin.vue?macro=true";
import { default as app_45config2KQuO0vje8VhaFHOziC8u2ME4YL2TEw17LnzAod3S30Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/app-config.vue?macro=true";
import { default as create_45workspace753iZBQVYRkO1E2yym9lpKQjDgKmvVL3T6elosnahfMMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index/create-workspace.vue?macro=true";
import { default as indexcRijQ68JINjVyEe1dLpirERPAp2SA5eaUzeUzqYT9eoMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index.vue?macro=true";
import { default as _91id_93drQyIUYx250orPBjhtOHtKS0ggOJsWmiVwU0o_45QojdQMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/[id].vue?macro=true";
import { default as createISTVQ2uh4ukxTAY9oJlkXxsYjNiYpNuGCFWgHzD9FMwMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/create.vue?macro=true";
import { default as indexEqxSEs3MCDGexpvw2S0x8mQ8xNBTuCW8z3I23dhUxgUMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/index.vue?macro=true";
import { default as usersQtls2kJXMRnnh8cj4AhHMRvHbyHAfPFbvZunGT84etQMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users.vue?macro=true";
import { default as _91connId_9379UU0oQmGuwo7_DSYbp_kivh0tYhgxc3OvTMBLoVw1YMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue?macro=true";
import { default as indexgPhhDM8gFennHAlikuMsG60iehHuRwYZQr0rPqNmGkAMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue?macro=true";
import { default as editiSO_45EE_45CXhdCLj6x8Ukpbrkh8y7KuNL1ogXnGtXfqRgMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue?macro=true";
import { default as indexanID_ici8Ih8o_xc_k86X6141Tgenb82THdt_R3x0IAMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index.vue?macro=true";
import { default as _91id_93_tcMuFKkB_45OM0JEKzgFhPjYkonQSL9fQkhC0CBdxHv0Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id].vue?macro=true";
import { default as indexegoCwGV_458WXdL9wDws76oQptYKDnv_5Xcw3113_mD2oMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/index.vue?macro=true";
import { default as app_45statuswXF_451_sFdvlj08ivCf71D4qQyg2izJP2F71GIZx8X2gMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/app-status.vue?macro=true";
import { default as changelogHeAPxohcuOg0vPG1M8GaevqLotiw7_45nQP8DL9mQI4B8Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/changelog.vue?macro=true";
import { default as influencing_45claimsk5UpFTlBtCk9HjCb0YhPYU13bwQEONwkaE1xjT4Xi7IMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue?macro=true";
import { default as _91id_93sA3hHDrA6q5vhah0WnGiJiZzYEocB0JA9rnqOQgqWtAMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id].vue?macro=true";
import { default as claimsVCWoL0nkBjD2rTvC8dNOvy_45twYpBZkaip28F0F6BctYMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/claims.vue?macro=true";
import { default as dashboardKyBJIa8_B2ctSfGHNdo08e_LgifNYbPSIf1ip631NVEMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/dashboard.vue?macro=true";
import { default as edit3w1xpF9oeVmlE09Mne13hfA831s_vaobmlaGFpnNo7AMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/edit.vue?macro=true";
import { default as indexM_MYwffGGB6aMytbDrlp2X2Ci88ZBPxqI9b4V_Jpky0Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/index.vue?macro=true";
import { default as createB4fcC_45APkw_45qKEJ8_454QdwyVi7y40HDyO1Z9ucfsDBM0Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/create.vue?macro=true";
import { default as indexzsXS5z4p_45yPAcJ35g5Kw7YsU4fjhblc9rDKOYzHkx4MMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/index.vue?macro=true";
import { default as exclusionsw7l_m9GFZhvb9Am4di1gUwPKiRNKaGblONbGboAJMRgMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions.vue?macro=true";
import { default as index3cq59CREBXbHdASibkdUW74Y1PvSgfoMEDMjRkr5ZAUMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/index.vue?macro=true";
import { default as maintenanceSymXC3DkIyYRsICphvUN7pSsqh9u32NSsCamC383wG8Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/maintenance.vue?macro=true";
import { default as providers_45impactedeCzW2opv8_45mCQf4NaOF8z3bPc_kTU7LKyARSgEsERpgMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue?macro=true";
import { default as _91id_93t9TFUzRWM5bNZc8jaZr6TTL6MeiWLtCn7rVLDELNFFIMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id].vue?macro=true";
import { default as indexWoVmVRlKOWpNFuno7LO84E2RsejmqRxe167IyVE6jxkMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies/index.vue?macro=true";
import { default as policiesOn6x4It4q4u1fIQSaRklK5AKcFpuQnSSzBzBlE_yGGgMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies.vue?macro=true";
import { default as reset_45password_45confirmKXUCzmwh1iceDn_DGmFwVRghfxi9gCMIvPuox1Kk1EMMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password-confirm.vue?macro=true";
import { default as reset_45passwordX7wDswXn_45aIgrtnSHp97nAOaT4iZjxnf0zo5liYSGM8Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password.vue?macro=true";
import { default as signinUFCL1lJYs_45_eRcZ_qTH4OMujZDiGTK_1EmKEruNaktUMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signin.vue?macro=true";
import { default as confirmg4bcNHYgazG4TtDM2P_45B42tkdcoI4vj_0q_45C26t5V_45sMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signup/confirm.vue?macro=true";
import { default as index_WkDEhwJL_tPnY979hnSphSotRanFDrRibdmwsf1lh0Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signup/index.vue?macro=true";
import { default as signupOu668XQNJMdLJrwGoZZFqhzwd5ekGYaJ2hlh4GX4tcQMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signup.vue?macro=true";
import { default as welcomeOknSoW5m4ulhe5qoegEGNOzkhg6FWlcET_3ORIOpHXkMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/welcome.vue?macro=true";
import { default as auth_45callbackEjVGi_328R450UGPBHL7gKxgGlePcBkSoCYV3Rd6AEAMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginektrxvC9NpudrdnB5U5wJtROq8hU5l0UajxzXe_45b5lAMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statusrX_Dpd_45iSI_FiKI_ZqylUrWR_45z_45mL5fHdtiX3WOE56QMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
import { default as saml585EciQ7kyaqExAP0bvjYYKkOiAAx71TD_45KXTOpMUv0Meta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth/src/pages/saml.vue?macro=true";
export default [
  {
    name: "connectorId",
    path: "/:connectorId()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue"),
    children: [
  {
    name: "connectorId-claim-lab-claimId-rid",
    path: "claim-lab/:claimId()/:rid()",
    meta: _91rid_93Ic1_45L3pj0PyUnmaFrLfQ2asxT_45aWTSHpOMyVHFDv2J8Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue")
  },
  {
    name: "connectorId-claim-lab",
    path: "claim-lab",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue")
  }
]
  },
  {
    name: "account-api-clients",
    path: "/account/api-clients",
    meta: api_45clientsWv8kAIq4bQChxIQ_VprJkAb66fqGxLM9oh3nYO7IGT8Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/account/api-clients.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexizq3_456UQYZFbtZbEy8LRxgQvoFts_wJe23uj5AAp1_gMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/account/index.vue")
  },
  {
    name: "account-users",
    path: "/account/users",
    meta: usersVq7i_SzZTTNdzAlN_Zu3iuakozZhipWDZS6r7Nn_jNIMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/account/users.vue")
  },
  {
    name: "admin-api-admin",
    path: "/admin/api-admin",
    meta: api_45adminnUrVtPvXDnFVKMt2rAqPOHosqqxxYXdByywxJqaSoJwMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/api-admin.vue")
  },
  {
    name: "admin-app-config",
    path: "/admin/app-config",
    meta: app_45config2KQuO0vje8VhaFHOziC8u2ME4YL2TEw17LnzAod3S30Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/app-config.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexcRijQ68JINjVyEe1dLpirERPAp2SA5eaUzeUzqYT9eoMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index.vue"),
    children: [
  {
    name: "admin-index-create-workspace",
    path: "create-workspace",
    meta: create_45workspace753iZBQVYRkO1E2yym9lpKQjDgKmvVL3T6elosnahfMMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index/create-workspace.vue")
  }
]
  },
  {
    name: usersQtls2kJXMRnnh8cj4AhHMRvHbyHAfPFbvZunGT84etQMeta?.name,
    path: "/admin/users",
    meta: usersQtls2kJXMRnnh8cj4AhHMRvHbyHAfPFbvZunGT84etQMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users.vue"),
    children: [
  {
    name: "admin-users-id",
    path: ":id()",
    meta: _91id_93drQyIUYx250orPBjhtOHtKS0ggOJsWmiVwU0o_45QojdQMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-create",
    path: "create",
    meta: createISTVQ2uh4ukxTAY9oJlkXxsYjNiYpNuGCFWgHzD9FMwMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/create.vue")
  },
  {
    name: "admin-users",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/index.vue")
  }
]
  },
  {
    name: _91id_93_tcMuFKkB_45OM0JEKzgFhPjYkonQSL9fQkhC0CBdxHv0Meta?.name,
    path: "/admin/workspace/:id()",
    meta: _91id_93_tcMuFKkB_45OM0JEKzgFhPjYkonQSL9fQkhC0CBdxHv0Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id].vue"),
    children: [
  {
    name: "admin-workspace-id",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index.vue"),
    children: [
  {
    name: "admin-workspace-id-index-connector-connId",
    path: "connector/:connId()",
    meta: _91connId_9379UU0oQmGuwo7_DSYbp_kivh0tYhgxc3OvTMBLoVw1YMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue")
  },
  {
    name: "admin-workspace-id-index-connector",
    path: "connector",
    meta: indexgPhhDM8gFennHAlikuMsG60iehHuRwYZQr0rPqNmGkAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue")
  },
  {
    name: "admin-workspace-id-index-edit",
    path: "edit",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue")
  }
]
  }
]
  },
  {
    name: "admin-workspace",
    path: "/admin/workspace",
    meta: indexegoCwGV_458WXdL9wDws76oQptYKDnv_5Xcw3113_mD2oMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/index.vue")
  },
  {
    name: "app-status",
    path: "/app-status",
    meta: app_45statuswXF_451_sFdvlj08ivCf71D4qQyg2izJP2F71GIZx8X2gMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/app-status.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    meta: changelogHeAPxohcuOg0vPG1M8GaevqLotiw7_45nQP8DL9mQI4B8Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/changelog.vue")
  },
  {
    name: "claims",
    path: "/claims",
    meta: claimsVCWoL0nkBjD2rTvC8dNOvy_45twYpBZkaip28F0F6BctYMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/claims.vue"),
    children: [
  {
    name: "claims-id",
    path: ":id()",
    meta: _91id_93sA3hHDrA6q5vhah0WnGiJiZzYEocB0JA9rnqOQgqWtAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id].vue"),
    children: [
  {
    name: "claims-id-insightId-influencing-claims",
    path: ":insightId()/influencing-claims",
    meta: influencing_45claimsk5UpFTlBtCk9HjCb0YhPYU13bwQEONwkaE1xjT4Xi7IMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue")
  }
]
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardKyBJIa8_B2ctSfGHNdo08e_LgifNYbPSIf1ip631NVEMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/dashboard.vue")
  },
  {
    name: exclusionsw7l_m9GFZhvb9Am4di1gUwPKiRNKaGblONbGboAJMRgMeta?.name,
    path: "/exclusions",
    meta: exclusionsw7l_m9GFZhvb9Am4di1gUwPKiRNKaGblONbGboAJMRgMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions.vue"),
    children: [
  {
    name: "exclusions-id-edit",
    path: ":id()/edit",
    meta: edit3w1xpF9oeVmlE09Mne13hfA831s_vaobmlaGFpnNo7AMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/edit.vue")
  },
  {
    name: "exclusions-id",
    path: ":id()",
    meta: indexM_MYwffGGB6aMytbDrlp2X2Ci88ZBPxqI9b4V_Jpky0Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/index.vue")
  },
  {
    name: "exclusions-create",
    path: "create",
    meta: createB4fcC_45APkw_45qKEJ8_454QdwyVi7y40HDyO1Z9ucfsDBM0Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/create.vue")
  },
  {
    name: "exclusions",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: index3cq59CREBXbHdASibkdUW74Y1PvSgfoMEDMjRkr5ZAUMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceSymXC3DkIyYRsICphvUN7pSsqh9u32NSsCamC383wG8Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/maintenance.vue")
  },
  {
    name: policiesOn6x4It4q4u1fIQSaRklK5AKcFpuQnSSzBzBlE_yGGgMeta?.name,
    path: "/policies",
    meta: policiesOn6x4It4q4u1fIQSaRklK5AKcFpuQnSSzBzBlE_yGGgMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies.vue"),
    children: [
  {
    name: "policies-id",
    path: ":id()",
    meta: _91id_93t9TFUzRWM5bNZc8jaZr6TTL6MeiWLtCn7rVLDELNFFIMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id].vue"),
    children: [
  {
    name: "policies-id-providers-impacted",
    path: "providers-impacted",
    meta: providers_45impactedeCzW2opv8_45mCQf4NaOF8z3bPc_kTU7LKyARSgEsERpgMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue")
  }
]
  },
  {
    name: "policies",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies/index.vue")
  }
]
  },
  {
    name: "reset-password-confirm",
    path: "/reset-password-confirm",
    meta: reset_45password_45confirmKXUCzmwh1iceDn_DGmFwVRghfxi9gCMIvPuox1Kk1EMMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password-confirm.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordX7wDswXn_45aIgrtnSHp97nAOaT4iZjxnf0zo5liYSGM8Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password.vue")
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinUFCL1lJYs_45_eRcZ_qTH4OMujZDiGTK_1EmKEruNaktUMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signin.vue")
  },
  {
    name: signupOu668XQNJMdLJrwGoZZFqhzwd5ekGYaJ2hlh4GX4tcQMeta?.name,
    path: "/signup",
    meta: signupOu668XQNJMdLJrwGoZZFqhzwd5ekGYaJ2hlh4GX4tcQMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signup.vue"),
    children: [
  {
    name: "signup-confirm",
    path: "confirm",
    meta: confirmg4bcNHYgazG4TtDM2P_45B42tkdcoI4vj_0q_45C26t5V_45sMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signup/confirm.vue")
  },
  {
    name: "signup",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signup/index.vue")
  }
]
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeOknSoW5m4ulhe5qoegEGNOzkhg6FWlcET_3ORIOpHXkMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/welcome.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callbackEjVGi_328R450UGPBHL7gKxgGlePcBkSoCYV3Rd6AEAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45loginektrxvC9NpudrdnB5U5wJtROq8hU5l0UajxzXe_45b5lAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statusrX_Dpd_45iSI_FiKI_ZqylUrWR_45z_45mL5fHdtiX3WOE56QMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  },
  {
    name: "AuthSaml",
    path: "/saml",
    meta: saml585EciQ7kyaqExAP0bvjYYKkOiAAx71TD_45KXTOpMUv0Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth/src/pages/saml.vue")
  }
]