import { defu } from 'defu'
import type { FetchContext, FetchHook, FetchOptions } from 'ofetch'
import { useWorkspace } from '~/stores/workspace'
import {
  makeTracingHeaders,
  useAppConfig,
  useFetch,
  useNuxtApp,
} from '#imports'

const createDefaultOptions = <T = unknown>({
  onRequest,
  ...opts
}: FetchOptions = {}): FetchOptions => {
  const externalOnRequest = onRequest

  const addAuthHeaders: FetchHook<FetchContext<T>> = async ({ options }) => {
    const appConfig = useAppConfig()
    const { $auth0, $auth } = useNuxtApp()
    const { getAccessTokenSilently, isAuthenticated } = $auth0()
    const workspace = useWorkspace()

    let token = ''

    if (isAuthenticated.value) {
      token = await getAccessTokenSilently()
    } else if (appConfig.tenant.supportCognitoAuth) {
      token = $auth.accessToken as string
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      'auth-provider': isAuthenticated.value ? 'auth0' : 'cognito',
      'x-rialtic-workspace-id': workspace.workspaceId,
      workspace: workspace.workspaceId,
      ...makeTracingHeaders(),
    }

    options.headers = defu(headers, options.headers)
  }

  if (!externalOnRequest) {
    return {
      ...opts,
      onRequest: [addAuthHeaders],
    }
  }

  return {
    ...opts,
    onRequest: [
      ...(Array.isArray(externalOnRequest)
        ? externalOnRequest
        : [externalOnRequest]),
      addAuthHeaders,
    ],
  }
}

export const $apiFetcher = () => {
  return <T>(url: string, options?: FetchOptions) =>
    $fetch<T>(url, createDefaultOptions(options))
}

type UseFetch = typeof useFetch

/**
 * @docs https://nuxt.com/docs/api/composables/use-fetch
 * @param url: string
 * @param options: UseFetchOptions
 *
 * @warning do not wrap this in a function, this should be used at the top level of a component's setup
 */
export const useApiFetch: UseFetch = (url, options) => {
  return useFetch(url, createDefaultOptions(options))
}
